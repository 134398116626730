/**
 * Created by henian.xu on 2017/3/29.
 * 
 */

.popup-wrap {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../images/mask.png") repeat left top;
  overflow: auto;

  .content {
    position: absolute;
    top: 100px;
    left: 50%;
    width: 640px;
    margin-left: -320px;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 100px;
    border-radius: 8px;

    > .close {
      position: absolute;
      right: -15px;
      top: -15px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 100%;
      font-size: 20px;
      text-align: center;
      background-color: $color-second;
      color: #fff;
      cursor: pointer;
      @include user-select(none);
    }
  }

}