//上圆角
@mixin border-top-radius($radius) {
  @include border-top-right-radius($radius);
  @include border-top-left-radius($radius);
}

//下圆角
@mixin border-bottom-radius($radius) {
  @include border-bottom-right-radius($radius);
  @include border-bottom-left-radius($radius);
}

//左圆角
@mixin border-left-radius($radius) {
  @include border-bottom-left-radius($radius);
  @include border-top-left-radius($radius);
}

//右圆角
@mixin border-right-radius($radius) {
  @include border-bottom-right-radius($radius);
  @include border-top-right-radius($radius);
}

//单行省略
@mixin text-line($line) {
  overflow: hidden;
  @if ($line == 1) {
    white-space: nowrap;
    text-overflow: ellipsis;
  } @else if ($line >= 2) {
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
  }

}
