/*间隔*/

$margin: 10px !default;
$padding: 10px !default;

$page-min-width: 1000px !default; //页面最小宽度

$grid-columns: 24 !default;
$grid-margin: 20px; //1rem;

//图标 sprit 路径

$iconSpritePath: '../images/'
