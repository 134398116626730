
.z-table-wrap {
  overflow-x: auto;
  border: 1px solid $color-border;
  > table {
    min-width: 100%;
    font-size: 12px;
    border-collapse: collapse;
    tr {
      border: solid $color-border;
      border-width: 1px 0 0 0;
      > th, > td {
        white-space: nowrap;
        text-align: center;
        padding: ((40px - 14*1.5)/2) 10px;
        > .z-cell-inner {
          min-width: 100%;
          white-space: normal;
        }
      }
      > th {
        background-color: #f7f7f7;
      }
      >td{
        background-color: #ffffff;
      }
      &:nth-child(even) {
        > td {
          background-color: #f9f9f9;
        }
      }
    }
    >thead{
      >tr{
        border-top:none;
      }
    }
    .z-ctrl {
      > a {
        color: $color-text;
        margin: 0 5px;
        &:hover {
          color: $color-second;
        }
      }
    }

    .J-label,
    .J-input {
      display: inline-block;
      width: 100%;
    }

    .J-input,
    .J-saveBtn,
    .J-cancelBtn {
      display: none;
    }

    .J-edit {
      .J-label,
      .J-editBtn,
      .J-removeBtn {
        display: none;
      }
      .J-input,
      .J-saveBtn,
      .J-cancelBtn {
        display: inline-block;
      }
    }
  }
}

.z-table-head,
.z-table-body,
.z-table-foot {
  overflow: hidden;
  > table {
    min-width: 100%;
    border-collapse: collapse;
    tr{
      > th, > td {
        border: solid $color-border;
        border-width: 0 1px 1px 0;
        padding: 10px;
        cursor: pointer;
      }
      > th {
        background-color: #f7f7f7;
      }
      >td{
        background-color: #ffffff;
      }
      &:nth-child(even) {
        > td {
          background-color: #f9f9f9;
        }
      }
    }
  }
}

.z-table-wrap{
  @include clearfix;
}

.z-table-body{
  overflow: auto;
}

.z-table-view1,
.z-table-view2{
  @include user-select(none);
}
.z-table-view1{
  float: left;
  .z-table-head,
  .z-table-body,
  .z-table-foot {
    >table{
      min-width: inherit;
    }
  }
  .z-table-body{
    overflow: hidden;
    >table{
      margin-bottom:16px;
    }
  }
}
.z-table-view2{

  .z-table-head{
    >table{
      margin-right:20px;
    }
  }
}