//cms列表
.cms-list {
  list-style: none;
  padding-left: 0;
  > li {
    @include clearfix;
    display: block;
    padding:$padding 0;
    border-top: 1px solid $color-border;
    &:first-child{
      padding-top:0;
      border-top: none;
    }
    > .media {
      float: left;
      width: 220px;
      height: 100px;
      margin-right: $margin;
      img {
        width: 100%;
        height: 100%;
      }
    }
    > .info {
      overflow: hidden;

      .title {
        @include text-line(1);
        display: block;
        font-size: 1.6rem;
        font-weight: bold;
      }
      .from {
        font-size: 1.2rem;
        margin:($margin/2) 0;
        .item {
          display: inline-block;
          margin-right: $margin;
          &,a{
            color: $gray-3;
          }
          span {
            display: inline-block;
            color: $color-text;
            margin-right: 5px;
          }
        }
      }
      .desc {
        @include text-line(2);
        font-size: 1.2rem;
        color: $gray-3;
      }
    }
  }
}