/**
* z-banner是从 z-list 扩展的
*/

//default theme---------------------------------------------------------------
$bannerHeader: 80px;
.z-banner {
  @include clearfix;
  font-size: 1.2rem;
  background-color: #fff;
  height: $bannerHeader;
  //line-height: $bannerHeader;

  .user-info{
    display: inline-block;
    >li{
      padding:0 10px;
      &+li{
        border-left: 1px solid $color-border;
      }
    }

  }

  .notice {
    @include clearfix;
    height: 30px;
    line-height:30px;
    width:200px;
    overflow: hidden;
    .icon-horn {
      margin-top:7px;
      float: left;
    }

    .inner{
      display: block;
      overflow: hidden;
    }

    .z-list{
      >li{
        @include text-line(1);
      }
    }
  }

  .logo{
    display: block;
  }
}