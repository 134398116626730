
.area-choose-tab{
  position: absolute;
  z-index: $z-index-4;
  top:100%;
  width:100%;
  min-width: 330px;
  max-width: 330px;
  background-color: #ffffff;
  border:1px solid $color-second;
  >.tab-nav{
    @include clearfix;
    background-color: $gray-1;
    >.item{
      float: left;
      display: block;
      width:80px;
      height:30px;
      line-height: 30px;
      text-align: center;
      border-left: 1px solid $color-border;
      border-bottom: 1px solid $color-border;
      cursor: pointer;
      &:first-child{
        border-left: none;
      }
      &.active{
        background-color: #ffffff;
        border-bottom-color: #ffffff;
      }
    }
  }
  .tab-content{
    >.tab-panel{
      display: none;
      padding: 10px;
      >span{
        display: inline-block;
        text-align: center;
        min-width: 60px;
        padding: 5px 10px;
        cursor: pointer;
        &:hover,
        &.active{
          background-color: $color-second;
          color: #ffffff;
        }
      }
      &.active{
        display: block;
      }
    }
  }
}