/**
* 各种组件样式定义
*/
@import "icon";
@import "type";
@import "layout";
@import "form";
@import "grid";
@import "dropdown";
@import "lists";
@import "button";
@import "table";

@import "attachment";

@import "banner";
@import "slider";
@import "nav";
@import "popup";
@import "footer";
@import "tabs";
@import "panels";
@import "card";
@import "article";

@import "toolbar";
@import "carGoods-list";
@import "sideBar";
@import "progress";
@import "search-box";
@import "pagination";
@import "security";
@import "baiduMap";
@import "cms-list";
@import "shareLink";

//插件样式
@import "swiper";
@import "webuploader";
@import "area-choose-tab";
@import "select2/core";
@import "ui-dialog";
@import "edui";
@import "player";
