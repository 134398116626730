.z-footer{
  //background-color: #303330;
  width:$page-min-width;
  margin:0 auto;
  border-top: 1px solid $color-border;
  font-size: 1.2rem;
  padding:20px 0 80px 0;
  text-align: center;
  //color: #ffffff;
  div{
    margin-bottom: 5px;
  }
}
