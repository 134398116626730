/**
 * Created by henian.xu on 2017/3/25.
 * 
 */

.slider-wrap {
  position: relative;
  width: 100%;
  min-width: $page-min-width;
  height: 380px;
  overflow: hidden;
  > .bg {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -(1920px/2);
    width: 1920px;
    height: 380px;
  }
  >.slider{
    position: relative;
    z-index: 100;
    padding-top:25px;
    @include clearfix;

    .right{
      width: 230px;
      float: right;
      @include clearfix;
      >.item{
        display: block;
        float: left;
        >.title{
          float: left;
          background-color: #1d91e6;
        }
        >.thumbnail{
          float: left;
        }

        &:hover,
        &.active{
          >.title{
            background-color: #ff7520;
          }
        }
      }

    }
    .content{
      overflow: hidden;
    }

  }
}

#slider{
  width:100%;
  height: 355px;

  .swiper-slide{
    position: relative;
    >img{
      width:100%;
      height:100%;
    }

    >.desc{
      position: absolute;
      z-index: 100;
      left:0;
      right:0;
      bottom:0;
      background: url("../images/mask.png")repeat left top;
      height:45px;
      line-height: 45px;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      @include text-line(1);
      padding-right: 200px;


    }
  }
  .swiper-pagination{
    position: absolute;
    bottom:0;
    left:auto;
    right:0;
    width: 200px;
    z-index: 500;
    padding-right:10px;
    text-align: right;
    height:45px;
    line-height: 45px;

    .swiper-pagination-switch{
      display: inline-block;
      width:10px;
      height: 10px;
      border-radius: 10px;
      margin:0 5px;
      background-color: #fff;
    }
    .swiper-visible-switch{
      background-color: #1d91e6;
    }

    .swiper-pagination-bullet{
      opacity:1;
      background-color: #fff;
    }
    .swiper-pagination-bullet-active{
      background-color: #1d91e6;
    }
  }
}

#slider-small{
  width:100%;
  height: 173px;

  .swiper-slide{
    position: relative;
    >img{
      width:100%;
      height:100%;
    }

    >.desc{
      position: absolute;
      z-index: 100;
      left:0;
      right:0;
      bottom:0;
      background: url("../images/mask.png")repeat left top;
      height:30px;
      line-height: 30px;
      color: #fff;
      font-size: 16px;
      @include text-line(1);
      padding-right: 150px;
      padding-left: 10px;

    }
  }
  .swiper-pagination{
    left:auto;
    right:0;
    width: 150px;
    z-index: 500;
    padding-right:10px;
    bottom: 5px;
    text-align: right;
    .swiper-pagination-bullet{
      opacity:1;
      background-color: #fff;
    }
    .swiper-pagination-bullet-active{
      background-color: #1d91e6;
    }
  }
}