/**
 * Created by henian.xu on 2017/5/18.
 * 
 */

.attachment {
  margin-bottom: 20px;
  > .title {
    background-color: #dedede;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    padding: 0 16px;
    > .icon-link {
      display: inline-block;
      background: url("../images/icon/link-icon.png") center no-repeat;
      width: 19px;
      height: 18px;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    > li {
      @include clearfix;
      padding: 0 16px;
      height:35px;
      line-height: 35px;
      margin-top:10px;
      > .icon-word {
        float: left;
        display: inline-block;
        background: url("../images/icon/word-icon.png") center no-repeat;
        width: 40px;
        height: 35px;
        vertical-align: middle;
      }
      > .icon-excel {
        float: left;
        display: inline-block;
        background: url("../images/icon/excle-icon.png") center no-repeat;
        width: 40px;
        height: 35px;
        vertical-align: middle;
      }
      >.label{
        float: left;
      }
      >.after{
        float: left;
        margin-left: 5px;
        color: #1c90e5;
      }
    }
  }
}