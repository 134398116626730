
.z-security-step-4{
  text-align: center;
  margin-bottom: $margin;
  >ul{
    @include clearfix;
    list-style: none;
    padding-left:0;
    display: inline-block;
    >li{
      float: left;
      width:100px;
      margin-left:195px;
      &:first-child{
        margin-left:0;
      }
    }
  }
  >.z-step-img{
    background: url("/static/images/security/step-4.png")no-repeat top left;
    display: inline-block;
    width:923px;
    height:44px;

    &.step-1{background-position:0 0;}
    &.step-2{background-position:0 -44px;}
    &.step-3{background-position:0 -88px;}
    &.step-4{background-position:0 -132px;}
  }
}

.z-security-step-3{
  text-align: center;
  >ul{
    @include clearfix;
    list-style: none;
    padding-left:0;
    display: inline-block;
    >li{
      float: left;
      width:150px;
      margin-left:180px;
      &:first-child{
        margin-left:0;
      }
    }
  }
  >.z-step-img{
    background: url("/static/images/security/step-3.png")no-repeat top left;
    display: inline-block;
    width:700px;
    height:44px;

    &.step-1{background-position:0 0;}
    &.step-2{background-position:0 -46px;}
    &.step-3{background-position:0 -92px;}
  }
}