//swiper 滑块插件样式

#bannerAD {
  width: 100%;
  height: 68px;
}

.bannerAd-wrap {
  @include clearfix;
  display: block;
  width: 100%;
  height: 68px;
  > .ad-left,
  > .ad-center,
  > .ad-right {
    display: block;
    float: left;
    height: 68px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  > .ad-left {
    width: 250px;
  }
  > .ad-center {
    width: 525px;
  }
  > .ad-right {
    width: 225px;
  }
}

