/**
* z-nav导航样式是从 z-list 扩展
*/
.z-nav-wrap {
}

.z-nav {
  list-style: none;
  padding-left: 0;
  @include clearfix;
  > li {
    float: left;
    white-space: nowrap;
  }
  .z-nav-label {
    display: block;
  }
  .z-after {
    position: absolute;
    right: $padding;
  }
  .z-icon {
    line-height: inherit;
    margin: 0 ($margin / 4);
  }
  //下拉菜单
  @include dropdow-menu('nav-sub');
}

//default theme---------------------------------------------------------------
.z-nav-wrap {
  background-color: $color-main;
}

.z-nav {
  font-size: 1.6rem;
  height: 68px;
  //line-height: 50px;
  background-color: $color-main;
  //border: 1px solid $gray-3;
  li {
    padding: 22px 0;
    >a{
      padding:0 40px;
      border-left:1px solid #fff;
    }
    &:first-child{
      >a{
        border-left: none;
      }
    }
  }
  > li:hover {
    background-color: darken($color-main, 5%);
  }
  > li.z-active{
    font-weight: bold;
    border-bottom: 4px solid $color-second;
    padding: 21px 0;
    &,>a{
      color: $color-second;
    }
  }
  .z-nav-label {
    color: #ffffff;
  }
  .z-nav-sub-list {
    background-color: $color-main;
    > li:hover {
      background-color: darken($color-main, 5%);
    }
  }
}

//waybill theme---------------------------------------------------------------
.z-theme-waybill {
  &.z-nav, .z-nav {
    background-color: $gray-2;
    > li {
      width:20%;
      padding: 0 20px;
      text-align: center;
      &.z-active {
        background-color: $color-main;
      }
    }
  }
}

//custom theme---------------------------------------------------------------
.z-theme-custom {
  &.z-nav-wrap, .z-nav-wrap {
    border-bottom: 3px solid $color-main;
  }
  &.z-nav, .z-nav {
    font-size: 1.6rem;
    height: 40px;
    line-height: 40px;
    background-color: $white;
    border: none;
    li {
      padding: 0 30px;
    }
    > li:hover {
      color: $color-main;
      background-color: $white;
    }
    .z-nav-sub-list {
      font-size: 1.2rem;
      color: $white;
      background-color: lighten($color-main, 5%);
      border: none;
      > li {
        border-top: 1px dotted $white;
        &:first-child {
          border-top: none;
        }
        &:hover {
          background-color: darken($color-main, 5%);
        }
      }
    }
    .z-nav-item-classify {
      &, &:hover {
        background-color: $color-main;
        color: $white;
      }
    }
  }
}


