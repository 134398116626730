/**
* 类似列表的样式基类
*/

.z-list {
  &.z-list-style-none {
    list-style: none;
    padding-left: 0;
  }

  &.z-list-horizontal {
    //水平的
    list-style: none;
    padding-left: 0;
    @include clearfix;
    > li {
      float: left;
    }
  }

  > li {
    .z-icon {
      margin: 0 5px;
    }
  }
  &.position-inner {
    //list-style-position: inside;
    padding-left: 20px;
  }
}

//标题
.z-list-label {
  //vertical-align: top;
  display: block;
}

.z-list-after {
  float: right;
}

//子菜单列表
.z-list-sub-list {
  list-style: none;
  display: none;
}

//子菜单项
.z-list-sub {
  &:hover {
    > .z-list-sub-list {
      display: block;
    }
  }
}

.z-list-horizontal {
  @include dropdow-menu('list-sub');
}

//default theme---------------------------------------------------------------

//home right list theme---------------------------------------------------------------

.hr-list {
  &.z-list {
    margin-top: 5px;
    list-style: none;
    > li {
      position: relative;
      font-size: 18px;
      line-height: 1.2;
      padding: 4px 0 4px 15px;
      &:before {
        content: '';
        @include sprite-dot-icon($iconSpritePath);
        position: absolute;
        left: 0;
        top: 5px;
      }
      &:hover {
        &, a {
          color: $color-main;
        }
        &:before {
          @include sprite-dot-hover-icon($iconSpritePath);
        }
        &:after {
          content: '';
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-right: 10px solid #d9d9d9;
          border-bottom: 10px solid transparent;
          position: absolute;
          right: -25px;
          top: 4px;
        }
        > .popup {
          display: block;
        }
      }
      .z-list-label {
        @include text-line(1);
      }
    }

    .popup {
      display: none;
      position: absolute;
      top: -50px;
      left: 203px;
      padding: 8px;
      border: 1px solid $color-border;
      font-size: 12px;
      color: #333;
      > .title {
        font-size: 16px;
        color: #000;
        margin: 5px 0 10px 0;
      }

      > .title,
      > .content {
        padding: 0 4px;
      }
    }
  }

  &.z-list-style-none {
    > li {
      padding-left: 0;

      &:before,
      &:hover:before {
        content: normal;
      }
    }
  }
}

.hr2-list {
  &.z-list {
    > li {
      line-height: 34px;
    }
  }
}

//custom theme---------------------------------------------------------------
.z-theme-cms {
  &.z-list {
    > li {
      line-height: 56px;
      border-bottom: 1px dashed $color-border;
    }
  }
  .z-list-label {
    @include text-line(1);
  }
  .z-list-after {
    padding-left: $padding;
  }
}

// 首页新闻列表
.new-list-home {
  list-style: none;
  padding-left: 0;
  > li {
    padding: 12px 0;
    border-bottom: 1px solid $color-border;
  }
  .item1 {
    padding: 13px 0;
    @include clearfix;
    > .img-obj {
      float: left;
    }
    > .desc {
      padding-left: 20px;
      overflow: hidden;

      > .title {
        display: block;
        @include text-line(2);
        font-size: 18px;
        //color: $color-text;
        font-weight: bold;
        height: 18px*1.5*2;
        margin-bottom: 10px;
      }
      > .sub {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .icon {
        margin-right: 5px;
      }
    }

  }
  .item2 {
    padding: 5px 0;
    > .desc {
      > .title {
        position: relative;
        z-index: 100;
        display: block;
        //color: $color-text;
        @include text-line(1);
        font-size: 18px;
        font-weight: bold;
        //margin-bottom: 5px;
        > .sub {
          position: relative;
          z-index: 200;
          float: right;
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          font-weight: normal;
          > .icon {
            margin-left: 10px;
          }
        }
      }
    }
    > .img-wrap {
      @include clearfix;
      > .img-obj {
        float: left;
        & + .img-obj {
          margin-left: 8px;
        }
      }
    }
  }

  &.sidebar {
    > .item1 {
      > .desc {
        > .title {
          font-size: 14px;
          line-height: 1.2;
          font-weight: normal;
          height: 14px*1.2*2;
          color: #838383;
          margin-bottom: 0;
        }
        > .sub {
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }
}

.new-list {
  list-style: none;
  padding-left: 0;
  > li {
    padding: 12px 0;
    border-bottom: 1px solid $color-border;
    margin-bottom: 20px;
  }
  .item1 {
    padding: 13px 0;
    @include clearfix;
    > .title {
      display: block;
      @include text-line(1);
      font-size: 24px;
      //font-weight: bold;
      padding-bottom: 6px;
      margin-bottom: 10px;
      border-bottom: 1px solid $color-border;
    }
    > .img-obj {
      float: left;
    }
    > .desc {
      padding-left: 20px;
      overflow: hidden;
      .text {
        @include text-line(4);
        height: 14px*1.5*4;
      }
      > .sub {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #999999;
        .source {
          margin-left: 10px;
          color: #999999;
        }
      }
      .icon {
        margin-right: 5px;
      }
    }

  }
  .item2 {
    padding: 5px 0;
    > .title {
      display: block;
      @include text-line(1);
      font-size: 24px;
      //font-weight: bold;
      padding-bottom: 6px;
      margin-bottom: 10px;
      border-bottom: 1px solid $color-border;
    }
    > .desc {
      .text {
        @include text-line(4);
      }
      > .sub {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #999999;
        .source {
          margin-left: 10px;
          color: #999999;
        }
      }
    }
    > .img-wrap {
      @include clearfix;
      margin-bottom: 10px;
      > .img-obj {
        float: left;
        & + .img-obj {
          margin-left: 20px;
        }
      }
    }
  }
  .item3 {
    padding: 5px 0;
    > .title {
      display: block;
      @include text-line(1);
      font-size: 24px;
      //font-weight: bold;
      padding-bottom: 6px;
      margin-bottom: 10px;
      border-bottom: 1px solid $color-border;
    }
    > .desc {
      .text {
        @include text-line(4);
      }
      > .sub {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #999999;
        .source {
          margin-left: 10px;
          color: #999999;
        }
      }
    }
    > .img-wrap {

      @include clearfix;
      margin-bottom: 10px;
      > .img-obj {
        display: inline-block;
      }
    }
  }
}

.play-list {
  list-style: none;
  padding-left: 0;

  > li {
    padding: 12px 0;
    border-bottom: 1px solid $color-border;
    position: relative;
    padding-right: 140px;

    > .icon {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }
  .item {
    padding: 5px 0;
    > .title {
      display: block;
      @include text-line(1);
      font-size: 20px;
      margin: 5px 0;
    }
    > .sub {
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      color: #999999;
      .time,
      .count,
      .source {
        font-size: 12px;
        color: #999999;
        margin-left: 40px;
      }
      .count {
        margin-left: 0;
      }
    }
    > .img-wrap {
      @include clearfix;
      > .img-obj {
        display: inline-block;
      }
    }
  }
}

.compere-list {
  list-style: none;
  padding-left: 0;
  @include clearfix;
  .item {
    float: left;
    text-align: center;
    margin: 5px 21px;

    .img-obj {
      display: inline-block;
    }
  }
}

.comment-list {
  list-style: none;
  padding-left: 0;
  > .item {
    //@include clearfix;
    padding: 10px 0;
    //border-top:1px solid $color-border;
    border-bottom: 1px solid $color-border;
    > .img-obj {
      float: left;
      margin-right: 10px;
      > img {
        border-radius: 100%;
      }
    }
    > .desc {
      overflow: hidden;
      > .title {
        display: block;
        font-size: 12px;
        .name {
          display: inline-block;
          @include text-line(1);
          color: $color-main;
          margin-right: 10px;
          vertical-align: middle;
        }
        > span {
          display: inline-block;
          color: #999999;
          vertical-align: middle;
        }
      }
      .content {
        font-size: 14px;
        line-height: 1.5;
        @include text-line(3);
        //height: (14px*1.5*3);
        margin-top: 5px;
      }
      .sub {
        font-size: 12px;
        line-height: 30px;
        &, a {
          color: #999;
        }
        > span, > a {
          margin-right: 10px;
        }
      }
    }
  }
}

.attachment-list {
  list-style: none;
  padding-left: 0;

  >li{
    padding: 5px 20px;
    border-bottom: 1px solid #dedede;
    >.title{
      display: block;
      font-size: 24px;
      > .icon-link {
        display: inline-block;
        background: url("../images/icon/link-icon.png") center no-repeat;
        width: 19px;
        height: 18px;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
    >.desc{
      font-size: 12px;
      color: #9999a5;
    }
  }

}

.reply-box,
.reply-edit {
  display: none;
}

.reply-box {
  border-top: 1px solid #dddddd;
  margin-top: 10px;
  dl {
    @include clearfix;
    padding: 5px 0;
    line-height: 20px;
    dt {
      float: left;
      font-size: 12px;
      &, > a {
        color: #379be9;
      }
    }
    dd {
      overflow: hidden;
      @include clearfix;

      .reply-text,
      .time {
        float: left;
      }
      .time {
        font-size: 12px;
        color: #999999;
      }
    }
  }

}
