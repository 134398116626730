//复位内外边框
blockquote, body, button, code, dd, div, dl, dt, fieldset, form, h1, h2, h3, h4, h5, h6, input, legend, li, ol, p, pre, td, textarea, th, ul, option {
  margin: 0;
  padding: 0;
}

//表单相关元素字体重置
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

strong{
  font-size: 1.1em;
}

//图像垂直对齐
img {
  vertical-align: middle;
}

//重置 hr 标签样式
hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid $color-border;
}
