//百度地图样式

.baidu-map{
  width:100%;
  background-color: $gray-1;
  min-height: 200px;
  &.waybill{
    height:700px;
  }
  &.carrierShow{
    height:325px;
  }
}

.baidu-map-wrap{
  position: relative;

  >.desc{
    position: absolute;
    left:0;
    width:100%;
    bottom:0;
    background-color: rgba(0,181,93,.5);
    padding: ($padding/2);
    color: #ffffff;
  }

}