.z-sideBar {
  list-style: none;
  padding-left: 0;
  width: 100%;
  min-height: 450px;
  padding-right: 90px;
  font-size: 24px;
  //font-weight: bold;

  .z-list-sub-list {
    display: block;
    font-size: 14px;
    font-weight: normal;
    border-top: 1px dashed $color-text;
    border-bottom: 1px dashed $color-text;
  }
  .z-list-title {
    display: block;
    padding: 0 30px;
    line-height: 54px;

    > a {
      position: relative;
      display: block;
      @include text-line(1);
      > .z-icon {
        display: none;
        position: absolute;
        right: 100%;
        top: 50%;
        margin-top: -(20px/2);
        line-height: 20px;
        font-size: 80%;
      }
      &:hover {
        color: $color-main;
        > .z-icon {
          display: block;
        }
      }
    }
    &.z-active {
      &, a {
        color: $color-main;
      }
      > a > .z-icon {
        display: block;
      }
    }
  }

  .qrcode {
    > div {
      font-size: 12px;
      color: $gray-3;
    }
  }

}