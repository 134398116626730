
.z-progress{
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  margin:0 5px;
  min-height: 12px;
  min-width: 100px;
  background-color: $gray-2;
  >.z-progress-inner{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    width:50%;
    background-color: $color-second;
  }
}