/**
* 标签页
*/

.z-tabs {
  position: relative;
  overflow: hidden;
}

.z-tabs-nav {
}

.z-tabs-tab-container {
  overflow: hidden;
}

.z-tabs-tab-prev,
.z-tabs-tab-next {
  display: none;
  position: absolute;
  top: 0;
  width: 20px;
  text-align: center;
  line-height: inherit;
  cursor: pointer;
  &.disabled {
    cursor: default;
    color: $gray-2;
  }
}

.z-tabs-tab-scroll {
  padding: 0 20px;
  position: relative;
  .z-tabs-tab-prev,
  .z-tabs-tab-next {
    display: block;
  }
  .z-tabs-tab-prev {
    left: 0;
  }
  .z-tabs-tab-next {
    right: 0;
  }
}

.z-tabs-tab-wrap {
  overflow: hidden;
  @include clearfix;
}

.z-tabs-tab-offset {
  white-space: nowrap;
  position: relative;
  float: left;
  @include clearfix;
  > .z-tabs-tab {
    cursor: pointer;
    display: inline-block;
  }
}

.z-tabs-content {
  > .z-tabs-pane {
    display: none;
    &.z-active {
      display: block;
    }
  }
}

//default theme---------------------------------------------------------------

//home theme---------------------------------------------------------------

.z-tabs-nav{
  line-height: 30px;
  border-bottom: 1px solid $color-border;
}
.z-tabs-tab{
  padding:0;
  font-size:16px;
  //font-weight: bold;
  &.z-active{
    position: relative;
    //color: $color-main;
    &:after{
      content: '';
      display: block;
      position: absolute;
      left:0;
      right:0;
      bottom: -2px;
      width:100%;
      height:3px;
      background-color: $color-main;
    }
  }
  &:hover{
    color: $color-main;
  }
}

.z-tabs.home{
  .z-tabs-nav {
    line-height: 35px;
    border-bottom: 1px solid $color-main;

    > .fn-right {
      font-size: 16px;
      &,a{
        color: #999;
      }
    }
  }
  .z-tabs-tab {
    padding: 0 5px;
    font-size: 20px;
    font-weight: normal;
    &.z-active {
      position: relative;
      color: $color-main;
      font-weight: bold;
      &:after {
        content: normal;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        width: 100%;
        height: 2px;
        background-color: $color-main;
      }
    }
    &:hover {
      color: $color-main;
    }
  }
}

