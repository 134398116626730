$inputHerght: 30px;
input,
select,
textarea {
  display: inline-block;
  border: 1px solid $color-border;
  border-radius: 5px;
  height: $inputHerght;
  line-height: ($inputHerght - 2) \0
;
  padding: 0 ($padding / 4);
  max-width: 100%;
  vertical-align: middle;
  outline: none;
  &:focus {
    //outline:1px solid $color-second;
    border-color: $color-second;
    -moz-outline-radius: 5px;
  }
}

textarea {
  height: auto;
  padding: $padding / 2;
}

input[type="checkbox"],
input[type="radio"] {
  &:focus {
    outline: none;
    -moz-outline-radius: 0;
  }
}

label {
  height: $inputHerght;
  line-height: $inputHerght;
  display: inline-block;
  &.ta-r {
    display: block;
  }
  &.z-normal {
    height: auto;
    line-height: inherit;
  }
}

//验证码
.z-captcha {
  width: 100%;
  height: 30px;
}

.z-valid {
  vertical-align: top;
  > label.error {
    display: block;
    font-size: $font-size;
    height: 1em;
    line-height: 1.1em;
    color: $color-second;
  }
}

.z-area-choose {
  position: relative;
  > label.error {
    display: inline-block;
    font-size: $font-size;
    height: 2em;
    line-height: 2em;
    padding: 0 1em;
    background-color: $color-second;
    color: #ffffff;
    white-space: nowrap;
    border-radius: 3px;

    position: absolute;
    z-index: $z-index-5;
    left: 0;
    bottom: 100%;
    margin-bottom: 5px;

    &:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 100%;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-top: 5px solid $color-second;
      border-right: 5px solid transparent;

    }
  }
}

.z-select2 {
  position: relative;
  display: inline-block;
  > select {
    width: 100%;
  }
}

.form-item {
  position: relative;
  display: block;
  margin: $margin/2 0;

  >label.error{
    position: absolute;
    top:50%;
    left: 100%;
    font-size: 16px;
    line-height: 30px;
    padding: 5px 10px;
    margin-top: -20px;
    margin-left: 8px;
    border-radius: 5px;
    white-space: nowrap;
    background-color: #ff751f;
    color: #fff;
    &:after{
      content: '';
      position: absolute;
      right:100%;
      top:50%;
      width: 0;
      height: 0;
      margin-top: -8px;
      border-top: 8px solid transparent;
      border-right: 8px solid #ff751f;
      border-bottom: 8px solid transparent;
    }
  }

}

.z-area-choose {
  > select {
    margin-right: 10px;
  }
}

.z-icheckbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid $color-border;
  background-color: transparent;
  &.checked {
    border-color: $color-second;
    background-color: $color-second;
  }
}

.login-box {
  margin: 0 auto;
  padding: 80px 120px;
  width: 696px;
  height: 566px;
  background: url("../images/enter/login-box-bg.png") no-repeat center;
}

.register-box {
  margin: 0 auto;
  padding: 80px 120px;
  width: 696px;
  height: 728px;
  background: url("../images/enter/register-box-bg.png") no-repeat center;
}

.retrieve-box {
  margin: 0 auto;
  padding: 80px 120px;
  width: 696px;
  height: 485px;
  background: url("../images/enter/retrieve-box-bg.png") no-repeat center;
}

.form {
  .form-item {
    textarea,
    input {
      width:100%;
      border:1px solid $color-border;
      border-radius: 0;
      height: 40px;
      line-height: 40px;
    }
    textarea{
      line-height:1.5;
      height:auto;
    }
  }
}

.enter-form {
  label {
    font-size: 28px;
    color: #797777;
    height: auto;
    line-height: 1.5;
  }
  .form-item {
    position: relative;
    border: 1px solid $color-border;
    margin-bottom: 35px;
    > .icon {
      position: absolute;
      top: 0;
      left: 0;
    }
    > .icon-select {
      left: auto;
      right: 0;
    }
    > select,
    > input {
      position: relative;
      z-index: 100;
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding-left: 60px;
      padding-right: 5px;
      background-color: transparent !important;
      border: none;
      font-size: 14px;
    }
    > select {
      padding-right: 60px;
      @include appearance(none);

      height: 20px \9
    ;
      line-height: normal \9
    ;
      margin-top: 15px \9
    ;
      margin-bottom: 15px \9
    ;
    }

    .valid-btn {
      position: absolute;
      z-index: 200;
      top: 10px;
      right: 10px;
      padding: 0 9px;
      border: 1px solid $color-border;
      font-size: 18px;
    }
    &.valid {
      > input {
        padding-right: 125px;
      }
    }
  }
  .login-btn {
    background: url("../images/enter/login-btn.png") no-repeat;
    width: 450px;
    height: 64px;
    margin: 40px 0;
  }
  .register-btn {
    background: url("../images/enter/register-btn.png") no-repeat;
    width: 450px;
    height: 64px;
    margin: 40px 0;
  }
  .submit-btn {
    background: url("../images/enter/submit-btn.png") no-repeat;
    width: 450px;
    height: 64px;
    margin: 0 0 40px 0;
  }
}

.preview-btn{
  display: inline-block;
  background: url("../images/tip-off/preview-btn.png") no-repeat;
  width: 157px;
  height: 46px;
  margin: 20px 0;
}

.tip-off-btn{
  display: inline-block;
  background: url("../images/tip-off/tip-off-btn.png") no-repeat;
  width: 229px;
  height: 46px;
  margin: 20px 0 20px 10px;
}
.tip-off-ok-btn{
  display: inline-block;
  background: url("../images/tip-off/tip-off-ok-btn.png") no-repeat;
  width: 205px;
  height: 46px;
}

.form-style2 {
  label {
    font-size: 14px;
    color: #797777;
    height: auto;
    line-height: 1.5;
  }
  .form-item {
    position: relative;
    border: 1px solid $color-border;
    //margin-bottom: 35px;
    > .icon {
      position: absolute;
      top: 0;
      left: 0;
      &.right{
        left:auto;
        right:0;
      }
    }
    > .icon-select {
      left: auto;
      right: 0;
    }
    > input {
      position: relative;
      z-index: 100;
      width: 100%;
      height: 32px;
      line-height: 32px;
      padding-left: 70px;
      padding-right: 40px;
      background-color: transparent;
      border: none;
      font-size: 14px;
    }

    .valid-btn {
      position: absolute;
      z-index: 200;
      top: 10px;
      right: 10px;
      padding: 0 9px;
      border: 1px solid $color-border;
      font-size: 18px;
    }
    &.valid {
      > input {
        padding-right: 125px;
      }
    }
  }
}