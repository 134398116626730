/**
 * Created by henian.xu on 2017/3/29.
 * 
 */
.player-box {
  background-color: #1d91e6;
  > .inner {
    @include clearfix;
    width: $page-min-width;
    height: 460px;
    margin: 0 auto;

    > .player {
      overflow: hidden;
      height: 460px;
    }
    > .play-list {
      float: right;
      width: 320px;
      background-color: #d0d0d0;
      height: 460px;

      > .title {
        @include clearfix;
        background-color: #f5f5f5;
        height: 40px;
        line-height: 40px;
        padding: 0 16px;
        > .after {
          float: right;
          .icon {
            vertical-align: sub;
          }
        }
      }

      ul {
        height: (414px - 50);
        overflow: auto;
        background-color: #f5f5f5;
        margin-top: 6px;
        padding: 6px 15px;
        list-style: none;
        > .item {
          @include clearfix;
          border-bottom: 1px solid #d0d0d0;
          .img-obj {
            float: left;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 10px;
          }
          .desc {
            overflow: hidden;
            > .name {
              font-size: 12px;
              color: #838188;
              > .after {
                float: right;
                color: #838188;
              }
            }
            > .text {
              font-size: 12px;
              color: #333;
            }
          }
        }
      }

      > .comment-box {
        position: relative;
        background-color: #f5f5f5;
        height: 50px;
        padding: 5px 15px;
        > input {
          width: 100%;
          height: 40px;
          line-height: 40px;
          border: none;
          background-color: #d0d0d0;
          color: #fff;
          padding-left: 10px;
          padding-right: 80px;
        }
        > .send-btn {
          position: absolute;
          right: 15px;
          top: 5px;
          display: inline-block;
          width: 70px;
          height: 40px;
          line-height: 40px;
          background-color: #1d91e6;
          font-size: 14px;
          color: #fff;
          text-align: center;
        }
      }

    }

    > .play-tabs {
      float: right;
      width: 320px;
      background-color: #f5f5f5;
      height: 460px;
      border: 1px solid #0f050b;

      .z-tabs-nav{
        border-color: #000;
      }

      .z-tabs-tab {
        text-align: center;
        font-size: 12px;
        line-height: 1.2;
        padding: 5px 0;

        .week {
          font-size: 16px;
        }

        &.z-active {
          color: #1d91e6;
          &:after {
            display: none;
          }
        }
      }

      .z-tabs-tab-next,
      .z-tabs-tab-prev {
        top: 50%;
        margin-top: -15px;
      }

      .z-tabs-pane{
        height: 406px;
        overflow: auto;
      }

      .play-list2{
        list-style: none;
        padding-left:0;
        >li{
          @include clearfix;
          padding:0 10px;
          line-height: 34px;

          >a{
            display: block;
          }

          .time,
          .program{
            float: left;
          }
          .state{
            display: none;
            float: right;
          }
          .time{
            width: 54px;
            text-align: center;
          }
          .program{
            padding-left: 20px;
            background: url("../images/playing/list-icon.png")left center no-repeat;
          }

          &.active{
            >a{
              color: #1d91e6;
            }
            .state{
              display: block;
            }
            .program{
              background-image: url("../images/playing/list-icon02.png");
            }
          }

        }
      }

    }

  }
}

.jp-video {

  .jp-gui {
    position: relative;
    > .jp-interface {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 45px;
      background: url("../images/mask.png") repeat left top;

      .jp-progress {
        position: absolute;
        z-index: 200;
        top: 50%;
        height: 6px;
        margin-top: -3px;
        left: 250px;
        right: 130px;
        width: auto;
        border-radius: 3px;
      }
      .jp-controls-holder {
        position: relative;
        top: 0;
        z-index: 100;
        margin: 0;
        width: 100%;
        height: 100%;

        > .jp-volume-controls {
          position: absolute;
          left: 5px;
          top: 50%;
          height: 16px;
          margin-top: -8px;
          width: 95px;
          overflow: hidden;
        }

        > .jp-controls {
          margin-top: 2px;
          margin-left: 120px;
        }
        > .jp-toggles {
          right: 5px;
          width: 55px;
          text-align: right;
          > .jp-repeat,
          > .jp-full-screen {
            display: inline-block;
          }
          > .jp-full-screen {
            margin-left: 5px;
          }
        }
      }
    }

    .jp-current-time {
      position: absolute;
      left: (250px - 65);
      text-align: right;
      top: 50%;
      width: 60px;
      height: 20px;
      line-height: 20px;
      margin: -10px 0 0 0;
      color: #fff;
    }
    .jp-duration {
      position: absolute;
      text-align: left;
      right: (130px - 65);
      top: 50%;
      width: 60px;
      height: 20px;
      line-height: 20px;
      margin: -10px 0 0 0;
      color: #fff;
    }

  }

  .jp-video-play-icon {
    width: 65px;
    height: 65px;
    margin-left: -(65px/2);
    margin-top: -(65px/2);

  }

}

.jp-video-460p {
  width: 680px;
}

.jp-video-460p .jp-video-play {
  margin-top: -(460px);
  height: (460px);
}

.jp-state-playing {
  .jp-interface {
    display: none;
  }
  &:hover {
    .jp-interface {
      display: block;
    }
  }
}