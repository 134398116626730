.article {

  .article-header {
    border-bottom: 1px solid $color-border;
    .title {
      //height: 40px;
      line-height: 40px;
      font-size: 24px;
    }
    .sub {
      font-size: 12px;
      color: #999999;
      margin-bottom: 5px;
    }
  }

  .article-footer {
    border-bottom: 1px solid $color-border;
    margin-bottom: 20px;
    .desc {
      text-align: right;
      font-size: 12px;
      margin-bottom: 5px;

      &, a {
        color: #333333;
      }
      > span {
        margin: 0 5px;
        > a {
          margin-left: 5px;
        }
      }
    }
  }

  .share {
    @include clearfix;
    line-height: 30px;
    margin-bottom: 5px;
    border:0;

    > .label {
      font-size: 12px;
      color: #333333;
      float: right;
    }
    ul {
      float: right;
      list-style: none;
      @include clearfix;
      li {
        float: right;
        padding: 0 5px;
        border-left: 1px solid $color-border;
      }
    }
  }

  .article-body {
    padding: 10px;
    p {
      margin-bottom: 5px;
      //text-indent: 2em;
    }
    img {
      max-width: 100% !important;
      margin-bottom: 5px;
    }
  }
}