
// Transitions
// IE10+

@mixin transition($transition...) {
    -webkit-transition: $transition;  /* Safari, Chrome */
       -moz-transition: $transition;  /* Firefox 4.0~16.0 */
            transition: $transition;  /* IE >9, FF >15, Opera >12.0 */
}

@mixin transition-property($property) {
    -webkit-transition-property: $property;
       -moz-transition-property: $property;  /* Firefox 4.0~16.0 */
            transition-property: $property;
}

@mixin transition-duration($duration) {
    -webkit-transition-duration: $duration;
       -moz-transition-duration: $duration;  /* Firefox 4.0~16.0 */
            transition-duration: $duration;
}

@mixin transition-timing-function($easing) {
    -webkit-transition-timing-function: $easing;
       -moz-transition-timing-function: $easing;  /* Firefox 4.0~16.0 */
            transition-timing-function: $easing;
}

@mixin transition-delay($delay) {
    -webkit-transition-delay: $delay;
       -moz-transition-delay: $delay;  /* Firefox 4.0~16.0 */
            transition-delay: $delay;
}
