/**
 * Created by henian.xu on 2017/3/25.
 * 
 */

.z-card {
  background-color: #ffffff;
  //padding: $padding;
}

.z-card-header {
  @include clearfix;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  margin:8px 0 0 0;
  //border-bottom: 1px solid $color-main;

  > .z-icon {
    line-height: inherit;
    float: left;
    margin-right: 10px;
    font-size: 30px;
    color: $color-main;
  }
}

.z-card-body {
  >.img-obj{
    display: block;
    border:1px solid $color-border;
  }
}

.z-card-footer {
}

.z-card-title {
  @include text-line(1);
}

.z-card-ctrl {
  float: right;
  padding-left: 5px;
  font-size: 14px;
  &, > a {
    color: $gray-3;
  }
  > a {
    padding: 5px;
    & + a {
      border-left: 1px solid $color-border;
    }
  }
}


