/**
 * Created by henian.xu on 2017/3/28.
 * 
 */

.edit-box {
  position: relative;
  margin-bottom: 20px;
  .send-btn {
    position: absolute;
    z-index: 99999;
    right: 5px;
    bottom: 6px;
    width: 70px;
    height: 30px;
    line-height: 30px;
    background-color: $color-main;
    color: #fff;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }
  .user-group {
    @include clearfix;
    display: block;
    position: absolute;
    z-index: 99999;
    left: 5px;
    bottom: 0;
    line-height: 40px;
    .img-obj {
      float: left;
      overflow: hidden;
      margin-right: 5px;
      img {
        border-radius: 100%;
      }
    }
    .name {
      overflow: hidden;
      width: 450px;
      @include text-line(1)
    }
  }
}

.edit-box {
  > .edui-default> .edui-default,
  > .edui-container {
    position: relative;
    padding-bottom: 42px;
    border-radius: 0;
    border: 1px solid $color-main;

    .edui-editor-toolbarbox{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-top: 1px solid #d4d4d4;
      box-shadow: none;
      line-height: 40px;
      height: 40px;

      .edui-editor-toolbarboxouter{
        line-height: 39px;
        height: 39px;
        background: transparent;
      }

      .edui-toolbar {
        text-align: right;
        line-height: 21px;
        padding-right: 80px;

        .edui-btn {
          padding: 5px;
        }
      }
    }

    .edui-editor-messageholder{
      display: none;
    }
  }
}

