//
// css sprite mixins - created with http://www.codeandweb.com/texturepacker
// exporter created by Rob Moorman
//
// $TexturePacker:SmartUpdate:17bcdc0f56f1faebff45784e68d4d330:7e282b1f206e92882a8c8f09f721b033:185dae8b119d3f097ef3af89788ed6c9$


@mixin sprite-TV-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 40px;
    height: 40px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -751px -2px; } }

@mixin sprite-answer-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 55px;
    height: 50px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -563px -2px; } }

@mixin sprite-arrow-b-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 30px;
    height: 30px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -904px -34px; } }

@mixin sprite-broadcast-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 40px;
    height: 40px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -709px -2px; } }

@mixin sprite-camera-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 30px;
    height: 30px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -927px -2px; } }

@mixin sprite-compere-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 25px;
    height: 25px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -936px -34px; } }

@mixin sprite-dot-hover-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 15px;
    height: 15px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -502px -54px; } }

@mixin sprite-dot-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 15px;
    height: 15px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -485px -54px; } }

@mixin sprite-edit-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -463px -54px; } }

@mixin sprite-foresee-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 71px;
    height: 71px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -148px -2px; } }

@mixin sprite-horn-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -441px -54px; } }

@mixin sprite-img-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -419px -54px; } }

@mixin sprite-img2-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 30px;
    height: 30px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -895px -2px; } }

@mixin sprite-location-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 30px;
    height: 30px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -770px -44px; } }

@mixin sprite-lookReply-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -397px -54px; } }

@mixin sprite-mobild-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 55px;
    height: 50px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -506px -2px; } }

@mixin sprite-new-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 40px;
    height: 40px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -667px -2px; } }

@mixin sprite-password-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 55px;
    height: 50px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -449px -2px; } }

@mixin sprite-playing-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 71px;
    height: 71px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -75px -2px; } }

@mixin sprite-praise-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -375px -54px; } }

@mixin sprite-praise2-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -353px -54px; } }

@mixin sprite-qqkj-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 30px;
    height: 30px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -738px -44px; } }

@mixin sprite-question-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 55px;
    height: 50px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -392px -2px; } }

@mixin sprite-qxw-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -331px -54px; } }

@mixin sprite-reply-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -309px -54px; } }

@mixin sprite-review-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 71px;
    height: 71px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -2px -2px; } }

@mixin sprite-select-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 55px;
    height: 50px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -335px -2px; } }

@mixin sprite-tel-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 66px;
    height: 32px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -802px -36px; } }

@mixin sprite-tel02-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 32px;
    height: 32px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -870px -36px; } }

@mixin sprite-tiem-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -287px -54px; } }

@mixin sprite-user-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -265px -54px; } }

@mixin sprite-userName-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 55px;
    height: 50px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -278px -2px; } }

@mixin sprite-userName03-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 32px;
    height: 32px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -861px -2px; } }

@mixin sprite-userName04-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -243px -54px; } }

@mixin sprite-userName2-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 66px;
    height: 32px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -793px -2px; } }

@mixin sprite-valid-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 55px;
    height: 50px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -221px -2px; } }

@mixin sprite-video-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 45px;
    height: 45px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -620px -2px; } }

@mixin sprite-video-samll-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -221px -54px; } }

@mixin sprite-wx-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 25px;
    height: 25px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -647px -49px; } }

@mixin sprite-wx2-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 30px;
    height: 30px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -706px -44px; } }

@mixin sprite-xl-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 25px;
    height: 25px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -620px -49px; } }

@mixin sprite-xl2-icon($path: './') {
    display: inline-block;
    overflow: hidden;
    width: 30px;
    height: 30px;
    background: {
        repeat: no-repeat;
        image: url('#{$path}icon.png');
        position: -674px -44px; } }

