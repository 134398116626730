/*颜色值设置*/

//灰系
$black: #000;
$gray-5: #333333; //20%
$gray-4: #666666; //40%
$gray-3: #999999; //60%
$gray-2: #dedede; //86%
$gray-1: #efefef; //94%
$white: #ffffff;

$color-main: #1d91e6 !default;
$color-second: #ff751f !default;
$color-body: #fff;

//其它颜色
$color-violet:#ba68c8 !default;
$color-green:#7eb63d !default;
$color-blue:#03a9f4 !default;
$color-orange:#ff9800 !default;
$color-pink:#ff5555 !default;

//字体颜色
$color-text: $gray-5 !default;
$color-headings-text: $gray-5 !default;
$color-link: $color-text !default;
$color-link-hover: $color-main !default;//darken($color-text, 0%) !default;

//边框颜色
$color-border: $gray-2 !default;
