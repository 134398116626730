//按钮
.z-btn {
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  font-size: $font-size;
  > .z-icon {
    margin: 0 ($margin / 4);
  }
}

.z-btn-group {
  font-size: 0;
  vertical-align: middle;
  .z-btn + .z-btn {
    margin-left: -1px; //这里的值(-1)应该是边框的宽度
  }
}

//default theme---------------------------------------------------------------
$button-height: 22px;
.z-btn {
  background-color: #fff;
  border: 1px solid $color-border;
  padding: (($button-height - 16)/2) 10px;
  border-radius: 5px;
  line-height: 1;
  &:hover {
    background-color: $gray-1;
  }
}

.z-btn-main {
  border-color: $color-main;
  color: $color-main;
  &:hover {
    background-color: $color-main;
    color: #ffffff;
  }
  &.z-fill {
    background-color: $color-main;
    color: #ffffff;
  }
}

.z-btn-second {
  border-color: $color-second;
  color: $color-second;
  &:hover {
    background-color: $color-second;
    color: #ffffff;
  }
  &.z-fill {
    background-color: $color-second;
    color: #ffffff;
  }
}

.z-btn-group {
  > .z-btn {
    border-radius: 0;
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}