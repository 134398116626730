
.share-box {

  > .z-icon-f1d7,
  > .z-icon-f1d5,
  > .z-icon-f18a {
    color: #ffffff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 1.8rem;
  }
  > .z-icon-f1d7 {
    background-color: $color-main;
  }
  > .z-icon-f1d5 {
    background-color: $color-blue;
  }
  > .z-icon-f18a {
    background-color: $color-pink;
  }
}

.share {
  @include clearfix;
  line-height: 30px;
  margin: 10px 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;

  > .label {
    font-size: 12px;
    color: #333333;
    float: left;
  }
  ul {
    float: left;
    list-style: none;
    @include clearfix;
    li {
      position: relative;
      z-index: 999;
      float: right;
      padding: 0 5px;
      border-left: 1px solid $color-border;
      > .desc {
        display: none;
        position: absolute;
        padding: 5px;
        bottom: 110%;
        left: 50%;
        margin-left: -50px;
        width: 100px;
        height: 100px;
        background-color: #fff;
        border: 1px solid #dedede;
        border-radius: 5px;
        > img {
          width: 100%;
          height: 100%;
        }
        &:before {
          content: ' ';
          position: absolute;
          left: 50%;
          top: 100%;
          width: 0;
          height: 0;
          margin-left: -11px;
          border-left: 11px solid transparent;
          border-right: 11px solid transparent;
          border-top: 11px solid #dedede;
        }
        &:after {
          content: ' ';
          position: absolute;
          left: 50%;
          top: 100%;
          width: 0;
          height: 0;
          margin-left: -10px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #fff;
        }
      }
      &:hover {
        > .desc {
          display: block;
        }
      }
    }
  }
}