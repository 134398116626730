//原生样式
.webuploader-container {
  position: relative;
}

.webuploader-element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.webuploader-pick {
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  display: inline-block;
  cursor: pointer;
  background: transparent;
  padding: 10px 15px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
  opacity: 0;
}

.webuploader-pick-hover {
  background: transparent;//#00a2d4;
}

.webuploader-pick-disable {
  opacity: 0.6;
  pointer-events: none;
}

//自定义样式

.z-webuploader {
  list-style: none;
  padding-left: 0;
  @include clearfix;
  > .wup-fileItem {
    position: relative;
    float: left;
    width: 140px;
    height: 140px;
    background-color: #efefef;
    margin-right: 10px;
    //overflow: hidden;
    text-align: center;
    >img{
      position: absolute;
      left:0;
      right:0;
      top:0;
      bottom:0;
      margin:auto;
      max-width: 100%;
      max-height: 100%;
    }

    >.wup-ctrl{
      /*display: none;
      position: absolute;
      right:0;
      bottom:0;
      left:0;
      width:100%;
      height:2em;*/
      //background: url("/static/images/mask-bg.png") repeat left top;
      cursor: pointer ;

      .wup-del,
      .wup-zoom{
        position: absolute;
        top:-10px;
        right:-10px;
        background-color: $color-second;
        border-radius: 10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        float: left;
        text-align: center;
        color: #ffffff;

        &.z-full{
          width:100%;
          font-size: 2em;
          line-height:80px;
        }
      }

      &.z-full{
        top:0;
        right:0;
        bottom:0;
        left:0;
        width:100%;
        height:100%;
      }
    }

    &:hover{
      >.wup-ctrl{
        display: block;
      }
    }

    &.wup-uploadBtn {
      display: none;
      font-size: 30px;
      color: #e6e6e6;
      background-color: transparent;
      text-align: center;
      border: 3px dashed #e6e6e6;
      background: url("../images/comm/upload-bg.png")no-repeat center;
      line-height: (80px - 6);
      cursor: pointer;
      overflow: visible;
      .wup-filePicker{
        position: absolute;
        left:0;
        right: 0;
        top:0;
        bottom:0;
        width:100%;
        height:100%;
        background-color:transparent;
      }
      .J-input{
        position: absolute;
        top:0;
        left:0;
      }
    }
  }
}

.webuploader-wrap{
  >h3{
    font-size: 16px;
    font-weight: normal;
    color: #aeaeae;
    margin-bottom:10px;
  }
}

//图片弹出窗样式
.z-popup-img{
  position: fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  width:100%;
  height:100%;
  background: url("/static/images/mask-bg.png") repeat left top;
  >img{
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:auto;
    max-width: 100%;
    max-height: 100%;
  }
}