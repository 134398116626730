//工具栏

.z-toolbar{

  .icon-wrap{
    line-height:120px;
    text-align: center;
    color: #ffffff;
    .icon-inner{
      .z-icon{
        font-size: 56px;
      }
      display: inline-block;
      line-height: $line-height;
      vertical-align: middle;
    }
  }
}