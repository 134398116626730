/*字体设置*/

$font-family-sans-serif: "微软雅黑", "Helvetica Neue", Helvetica, Arial, sans-serif; //无衬线
$font-family-serif: Georgia, "Times New Roman", Times, serif; //有衬线
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace; //等宽
$font-family: $font-family-sans-serif !default; //默认基础字体

$html-font-size: 62.5% !default;
$font-size: 1.4rem !default;
$line-height: 15/10 !default; //行高

$font-headings-weight: bolder !default; //标题是否加粗
$line-height-headings: 20/10 !default; //标题行高
