
.z-panel {
  background-color: #ffffff;
  //padding: $padding;
}

.z-panel-header {
  @include clearfix;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid $color-main;

  > .z-icon {
    line-height: inherit;
    float: left;
    margin-right: 10px;
    font-size: 30px;
    color: $color-main;
  }
}

.z-panel-body {

}

.z-panel-footer {
}

.z-panel-title {
  @include text-line(1);
  color: $color-main;

  > a {
    margin-left: 10px;
    color: $color-text;
    font-size: 18px;
    font-weight: normal;
  }
}

.z-panel-ctrl {
  float: right;
  padding-left: 5px;
  font-size: 16px;
  font-weight: normal;
  &, > a {
    color: $gray-3;
  }
  > a {
    padding: 5px;
    & + a {
      border-left: 1px solid $color-border;
    }
  }
}

.z-panel.box {
  > .z-panel-header {
    border: none;
    height: 50px;
    line-height: 50px;
    background-color: $color-main;
    padding: 0 10px;

    .z-panel-title {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
    }
  }

  > .z-panel-body {
    border-left: 1px solid $color-border;
    border-right: 1px solid $color-border;
    border-bottom: 1px solid $color-border;
    padding: 10px;
  }
}

.z-panel.sidebar {
  > .z-panel-header {
    border: none;
    height: 50px;
    line-height: 50px;
    border-top: 2px solid $color-main;
    border-bottom: 1px solid $color-border;

    .z-panel-title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  > .z-panel-body {
    padding: 10px 0;
  }
}

.z-panel.compere {
  > .z-panel-header {
    border: none;
    height: 40px;
    line-height: 40px;
    border-bottom: 2px solid $color-border;

    .z-panel-title {
      position: relative;
      display: inline-block;
      padding: 0 20px 0 0;
      top: -2px;
      font-size: 25px;
      font-weight: bold;
      color: $color-second;
      border-bottom: 2px solid $color-main;
    }
  }

  > .z-panel-body {
    padding: 10px 0;
  }
}

