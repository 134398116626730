//车源与货源列表样式

.carGoods-list {
  list-style: none;
  padding-left: 0;

  display: table;
  border-collapse: collapse;
  width:100%;

  > li {
    display: table-row;
    width:100%;
    border-bottom: 1px solid $color-border;
    &:first-child {
      border-top: 1px solid $color-border;
    }
    >.cell{
      display: table-cell;
      padding: 6px $padding/2;
      vertical-align: middle;
      width:1%;
      line-height:30px;
    }

    .addr{
      >.z-icon{
        font-size: 16px;
      }
    }
  }
}