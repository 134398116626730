/**
* 搜索框
*
*/

.z-search-box {
  display: inline-block;
  > .z-search-dropdown,
  > .z-search-input > input,
  > .z-search-btn {
    line-height: 30px;
    border: none;
    padding: 0;
  }
}

//default theme---------------------------------------------------------------
.z-search-box {
  position: relative;
  min-width: 650px;

  padding: 0 80px 0 100px;
  border: 2px solid $color-main;
  > .z-search-dropdown,
  > .z-search-input > input,
  > .z-search-btn {
    line-height: 36px;
  }
  > .z-search-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    padding: 0 $padding;
    //text-align: right;
  }
  > .z-search-input > input {
    width: 100%;
    outline: none;
  }
  > .z-search-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    background-color: $color-main;
    color: $white;
    &:hover {
      background-color: lighten($color-main, 5%);
    }
  }
}





