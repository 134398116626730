//栅格系统

@mixin make-grid-columns($col) {
  @for $i from 1 through $col {
    .z-col-#{$i} {
      width: (100% / $col * $i);
    }
  }
}

@mixin make-grid-columns-per($col) {
  @for $i from 1 through $col {
    .z-col-#{$i*10}per {
      width: ($i * 10%);
    }
  }
}

.z-container {
  width: $page-min-width;
  margin-left: auto;
  margin-right: auto;

  /*> .z-row {
    margin-right: ($grid-margin / -2);
    margin-left: ($grid-margin / -2);
  }*/
}

.z-row {
  @include clearfix;
  margin-top: ($grid-margin / 1);
  margin-bottom: ($grid-margin / 1);

  margin-right: ($grid-margin / -2);
  margin-left: ($grid-margin / -2);
}

@include make-grid-columns($grid-columns);
@include make-grid-columns-per(10);

[class*="z-col-"] {
  float: left;
  padding-left: ($grid-margin / 2);
  padding-right: ($grid-margin / 2);

  /*&:after {
    content: 'grid';
    display: block;
    background-color: $gray-3;
  }*/
}

.z-row {
  &.no-gutter-a {
    margin: 0;
    > [class*="z-col-"] {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.no-gutter-tb {
    margin-top: 0;
    margin-bottom:0;
  }
  &.no-gutter-lr {
    > [class*="z-col-"] {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.z-table {
    display: table;
    width: 100%;
    > [class*="z-col-"] {
      display: table-row;
      float: none;
      > [class*="z-col-"] {
        display: table-cell;
        vertical-align: middle;
        float: none;
      }
    }
  }
}