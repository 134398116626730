
.ui-dialog {
  .ui-dialog-button {
    float: none;
    text-align: center;
  }

  .ui-dialog-footer {
    button {
      color: $color-main;
      background-color: #e2f5ec;
      border-color: $color-main;


      &.ui-dialog-autofocus {
        color: #ffffff;
        background-color: $color-main;
        border-color: $color-main;
      }

      &:hover,
      &:focus,
      &:active,
      &.ui-dialog-autofocus:hover,
      &.ui-dialog-autofocus:focus,
      &.ui-dialog-autofocus:active {
        color: #ffffff;
        background-color: darken($color-main,5%);
        border-color: darken($color-main,10%);
      }
    }

  }
}