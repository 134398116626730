/*html,body,
.page-group,.page {
  height:100%;
}*/

.page {
  > .z-header {
    z-index: $z-index-3;
    padding-top: 20px;
  }
  > .z-content {
    z-index: $z-index-1;
    padding: 5px 0;
  }
  > .z-footer {
    z-index: $z-index-2;
    margin-top: 30px;
  }
  > .z-components {
    z-index: $z-index-4;
  }


  .main-left,
  .main-content,
  .main-right{
    float: left;
  }
}

.page.enter {
  > .z-header {
    //height: 50px;
    line-height: 50px;
    background-color: #bdbec2;
    font-size: 20px;
    margin-top:0;
    &,a{
      color: #fff;
    }
  }
  > .z-content {
    z-index: $z-index-1;
    padding: 5px 0;
  }
  > .z-footer {
    z-index: $z-index-2;
    margin-top: 30px;
  }
  > .z-components {
    z-index: $z-index-4;
  }

}
