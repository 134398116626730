/*基础定义*/

* {
  border: none;
  font: inherit;
  margin: 0;
  padding: 0;
  text-rendering: geometricPrecision; //如何优化显示文本

  @include box-sizing(border-box);
  &:before, &:after {
    @include box-sizing(border-box);
  }

}

em{
  font-style: italic;
}

html {
  font-size: $html-font-size;
  height: 100%;
}

body {
  color: $color-text;
  background-color: $color-body;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  //min-height: 100%;
  position: relative;
}
.icon{
  vertical-align: middle;
}