/*文本版式*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-family;
  font-weight: $font-headings-weight;
  line-height: $line-height-headings;
  //color: $color-headings-text;
  margin-top: $margin/2;
  margin-bottom: $margin/2;
}

h1, .h1 {
  font-size: 3.6rem;
}

h2, .h2 {
  font-size: 3.2rem;
}

h3, .h3 {
  font-size: 2.8rem;
}

h4, .h4 {
  font-size: 2.0rem;
}

h5, .h5 {
  font-size: 1.8rem;
}

h6, .h6 {
  font-size: 1.6rem;
}

small {
  font-size: 65%;
}

p {
  //padding-bottom: 2.4rem;
}

//代码
pre, code, samp, kbd {
  background-color: $gray-1;
  font-size: 1.4rem;
  color: $color-main;
  font-family: $font-family-monospace;
  line-height: 1;
}

code,
samp,
kbd {
  border-radius: 2px;
  overflow-y: hidden;
  overflow-x: auto;
  padding: .4rem .4rem .2rem;
}

pre {
  border-radius: 2px;
  line-height: $line-height;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 1.2rem 1rem;
  margin: 0 0 2.4rem;
  code,
  samp,
  kbd {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
  }
}

//网站样式------------------------
//链接默认样式
a {
  color: $color-link; //;$main-color;
  text-decoration: none;

  //&:focus,
  &:hover {
    color: $color-link-hover;
    text-decoration: none;
  }
}

.section-title {
  font-size: 24px;
  font-weight: normal;
  padding: 5px 10px;
  border-bottom:1px solid $color-border;
}

.section-title2 {
  font-size: 46px;
  color: #ff751f;
  border-bottom: 4px solid $color-main;
  vertical-align: bottom;
  >.sub{
    font-size: 26px;
    color: #bdbdbd;
  }
}

.section-title3 {
  font-size: 20px;
  color: #fff;
  background-color: #b7dcf7;
  padding:5px 10px;
}


