/*z-index variables*/

$z-index-0:    -1;
$z-index-1:   100;//.page>.content
$z-index-2:   200;//.page>.footer
$z-index-3:   300;//.page>.header
$z-index-4:   400;//.page>.components
$z-index-5:   500;//验证错误
$z-index-6:   600;//弹出菜单
$z-index-7:   700;
$z-index-8:   800;
$z-index-9:   900;
$z-index-10: 1000;
