/**
 * Created by henian.xu on 2017/3/25.
 * 
 */

.icon-user {
  @include sprite-user-icon($iconSpritePath);
}

.icon-edit {
  @include sprite-edit-icon($iconSpritePath);
}

.icon-horn {
  @include sprite-horn-icon($iconSpritePath);
}

.icon-video {
  @include sprite-video-icon($iconSpritePath);
}

.icon-TV {
  @include sprite-TV-icon($iconSpritePath);
}

.icon-broadcast {
  @include sprite-broadcast-icon($iconSpritePath);
}

.icon-new {
  @include sprite-new-icon($iconSpritePath);
}

.icon-wx {
  @include sprite-wx-icon($iconSpritePath);
}

.icon-xl {
  @include sprite-xl-icon($iconSpritePath);
}

.icon-video-samll {
  @include sprite-video-samll-icon($iconSpritePath);
}

.icon-img {
  @include sprite-img-icon($iconSpritePath);
}

.icon-arrow-b {
  @include sprite-arrow-b-icon($iconSpritePath);
}

.icon-userName {
  @include sprite-userName-icon($iconSpritePath);
}

.icon-password {
  @include sprite-password-icon($iconSpritePath);
}

.icon-mobile {
  @include sprite-mobild-icon($iconSpritePath);
}

.icon-valid {
  @include sprite-valid-icon($iconSpritePath);
}

.icon-answer {
  @include sprite-answer-icon($iconSpritePath);
}

.icon-select {
  @include sprite-select-icon($iconSpritePath);
}

.icon-question {
  @include sprite-question-icon($iconSpritePath);
}

.icon-img2 {
  @include sprite-img2-icon($iconSpritePath);
}

.icon-camera {
  @include sprite-camera-icon($iconSpritePath);
}

.icon-location {
  @include sprite-location-icon($iconSpritePath);
}

.icon-userName2 {
  @include sprite-userName2-icon($iconSpritePath);
}

.icon-userName3 {
  @include sprite-userName03-icon($iconSpritePath);
}

.icon-tel {
  @include sprite-tel-icon($iconSpritePath);
}

.icon-tel2 {
  @include sprite-tel02-icon($iconSpritePath);
}

.icon-userName04 {
  @include sprite-userName04-icon($iconSpritePath);
}

.icon-time {
  @include sprite-tiem-icon($iconSpritePath);
}

.icon-qxw {
  @include sprite-qxw-icon($iconSpritePath);
}

.icon-playing {
  @include sprite-playing-icon($iconSpritePath);
}

.icon-review {
  @include sprite-review-icon($iconSpritePath);
}

.icon-foresee {
  @include sprite-foresee-icon($iconSpritePath);
}

.icon-compere {
  @include sprite-compere-icon($iconSpritePath);
}

.icon-xl2 {
  @include sprite-xl2-icon($iconSpritePath);
}

.icon-qqkj {
  @include sprite-qqkj-icon($iconSpritePath);
}

.icon-wx2 {
  @include sprite-wx2-icon($iconSpritePath);
}

.icon-praise {
  @include sprite-praise-icon($iconSpritePath);
}

.icon-reply {
  @include sprite-reply-icon($iconSpritePath);
}

.icon-lookReply {
  @include sprite-lookReply-icon($iconSpritePath);
}

.icon-praise2 {
  @include sprite-praise2-icon($iconSpritePath);
}

.icon-TV {
  @include sprite-TV-icon($iconSpritePath);
}

.icon-TV {
  @include sprite-TV-icon($iconSpritePath);
}
