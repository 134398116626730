.pagination{
  @include clearfix;
  text-align: center;
  >.inner{
    display: inline-block;
    >.item{
      float: left;
      width:40px;
      height:40px;
      line-height: 40px;
      text-align: center;
      background-color: #f7f7f7;
      &:hover,
      &.active{
        background-color: $color-second;
        color: #fff;
      }
      &:hover{
        background-color: #ff9553;
      }
    }
  }
}