/*公用类*/

//浮动与清除浮动
.fn-left {
  float: left;
}

.fn-right {
  float: right;
}

.fn-clear {
  @include clearfix();
}

.fn-hidden {
  overflow: hidden !important;
}

.fn-hidden-x {
  overflow-x: hidden !important;
}

.fn-hidden-y {
  overflow-y: hidden !important;
}

//内外边框
@mixin make-margin($margin-type:'margin',$isNone:false,$margin-list:(a:'', t:-top, r:-right, l:-left, b:-bottom)) {
  $short: 'ma';
  $none: '';
  @if ($margin-type == 'margin') {
    $short: 'ma';
  } @else {
    $short: 'pa';
  }

  @if ($isNone == true) {
    $none: 'n';
    $margin: 0;
  }

  @each $key, $value in $margin-list {
    .#{$none}#{$short}-#{$key} {
      @if ($isNone == true) {
        #{$margin-type}#{$value}: 0 !important;
      }@else {
        #{$margin-type}#{$value}: $margin !important;
      }
    }
  }
}

@include make-margin('margin');
@include make-margin('margin', true);

@include make-margin('padding');
@include make-margin('padding', true);

//文本对齐
@mixin make-text-align($align-list:(c:center, l:left, r:right,j:justify)) {
  @each $key, $value in $align-list {
    .ta-#{$key} {
      text-align: $value !important;
    }
  }
}

@include make-text-align();

//隐藏
.z-hide {
  display: none !important;
}

//宽度
.z-w100-per { //暂时保留下个版本 删除
  width: 100% !important;
}

@for $i from 1 through 20 {
  .w-#{$i*5}per {
    width: $i * 5% !important;
  }
}

@for $i from 1 through 40 {
  .w-#{$i*5}px {
    width: $i * 5px !important;
  }
}

@for $i from 1 through 100 {
  .w-#{$i}em {
    width: $i + em !important;
  }
}

//高度
@for $i from 1 through 20 {
  .h-#{$i*5}per {
    height: $i * 5% !important;
  }
}

@for $i from 1 through 40 {
  .h-#{$i*5}px {
    height: $i * 5px !important;
  }
}

@for $i from 1 through 100 {
  .h-#{$i}em {
    height: $i + em !important;
  }
}

// 行高
@for $i from 1 through 20 {
  .lh-#{$i*5}per {
    line-height: $i * 5% !important;
  }
}

@for $i from 1 through 40 {
  .lh-#{$i*5}px {
    line-height: $i * 5px !important;
  }
}

@for $i from 1 through 100 {
  .lh-#{$i}em {
    line-height: $i + em !important;
  }
}

.lh-n{
  line-height: $line-height !important;
}

//字体大小
.fs-small {
  font-size: 1.2rem !important;
}

.fs-normal {
  font-size: $font-size !important;
}

.fs-big {
  font-size: 1.6rem !important;
}

.fs-more {
  font-size: 1.8rem !important;
}

.fs-30{
  font-size: 3.0rem;
}

.fs-super {
  font-size: 3.6rem !important;
}

// 字体样式
.fw-b{
  font-weight: bold;
}
.fw-n{
  font-weight: normal;
}

//字体颜色
.tc-base {
  color: $color-text !important;
}

.tc-g1 {
  color: $gray-1 !important;
}

.tc-g2 {
  color: $gray-2 !important;
}

.tc-g3 {
  color: $gray-3 !important;
}

.tc-g4 {
  color: $gray-4 !important;
}

.tc-g5 {
  color: $gray-5 !important;
}

.tc-b {
  color: $black !important;
}

.tc-w {
  color: $white !important;
}

.tc-main {
  color: $color-main !important;
}

.tc-second {
  color: $color-second !important;
}

.tc-violet {
  color: $color-violet !important;
}

.tc-green {
  color: $color-green !important;
}

.tc-blue {
  color: $color-blue !important;
}

.tc-orange {
  color: $color-orange !important;
}

.tc-pink {
  color: $color-pink !important;
}

//文本对齐
.ta-l {
  text-align: left !important;
}

.ta-r {
  text-align: right !important;
}

.ta-c {
  text-align: center !important;
}

// 竖直对齐
.va-t{
  vertical-align: top !important;
}
.va-m{
  vertical-align: middle !important;
}
.va-b{
  vertical-align: bottom !important;
}

//背景颜色
.bc-base {
  background-color: $color-text !important;
}

.bc-g1 {
  background-color: $gray-1 !important;
}

.bc-g2 {
  background-color: $gray-2 !important;
}

.bc-g3 {
  background-color: $gray-3 !important;
}

.bc-g4 {
  background-color: $gray-4 !important;
}

.bc-g5 {
  background-color: $gray-5 !important;
}

.bc-b {
  background-color: $black !important;
}

.bc-w {
  background-color: $white !important;
}

.bc-main {
  background-color: $color-main !important;
}

.bc-second {
  background-color: $color-second !important;
}

.bc-violet {
  background-color: $color-violet !important;
}

.bc-green {
  background-color: $color-green !important;
}

.bc-blue {
  background-color: $color-blue !important;
}

.bc-orange {
  background-color: $color-orange !important;
}

.bc-pink {
  background-color: $color-pink !important;
}

//模盒
.dp-b {
  display: block !important;
}

.dp-i {
  display: inline !important;
}

.dp-ib {
  display: inline-block !important;
}

//边框
.bor-a {
  border: 1px solid $color-border !important;
}

.bor-n {
  border:none !important;
}

.bor-l {
  border-left: 1px solid $color-border !important;
}

.bor-r {
  border-right: 1px solid $color-border !important;
}

.bor-t {
  border-top: 1px solid $color-border !important;
}

.bor-b {
  border-bottom: 1px solid $color-border !important;
}
