/**
*下拉菜单
*/

//下拉菜单生成器
@mixin dropdow-menu($name) {
  .z-#{$name} {
    position: relative;
    &:hover {
      > .z-#{$name}-list {
        display: block;
      }
    }
  }
  ul.z-#{$name}-list, ol.z-#{$name}-list {
    list-style: none;
    padding-left: 0;
  }
  .z-#{$name}-list {
    display: none;
    position: absolute;
    z-index: $z-index-6;
    top: 100%;
    left: 0;
    min-width: 100%;

    .z-#{$name}-list {
      top: 0;
      left: 100%;
    }
  }
}

@include dropdow-menu('dropdown');

.z-dropdown {
  display: inline-block;
}

//default theme---------------------------------------------------------------
.z-dropdown {
  background-color: $white;
  border: 1px solid $color-border;
  padding: ($padding/2) $padding;
  .z-icon {
    margin: 0 ($margin/4);
  }
}
.z-dropdown-title {
  white-space: nowrap;
}
.z-dropdown-list {
  background-color: $white;
  border: 1px solid $color-border;
  > li {
    padding: 0 $padding;
    line-height: 40px;
    cursor: pointer;
    &:hover {
      background-color: $gray-1;
    }
  }
}